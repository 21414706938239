import { Header } from "../../../../components";
import { usePerformanceReport } from "../../../../hooks";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const CacheRatio = () => {
    const reportParams = {
        report: "cache_ratio",
    };

    const { data, isLoading, isError } = usePerformanceReport(reportParams);

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <Header style={{ marginTop: "20px" }} as="h3" textStyle="text-header-L">
                {`Cache Ratio: ${(data?.cache_ratio * 100).toFixed(2)}%`}
            </Header>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
        </RenderMetric>
    );
};

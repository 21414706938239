import { CreateExperimentFormData, CreateExperimentRequest } from "../../../../types";

export const buildCreateExperimentRequest = (
    formData: CreateExperimentFormData,
    storeId: string = "",
    orgId: string = "",
    customerUrl: string = "",
): CreateExperimentRequest => {
    if (!storeId) throw new Error("storeId is required");
    if (!orgId) throw new Error("orgId is required");

    const { experimentName, pageSetName, pagePattern, pageType, samplePageUrl, isDesktopEligible, isMobileEligible } =
        formData;
    const pageSet = {
        name: pageSetName,
        pagePattern: pagePattern,
        pageType: pageType,
        sampleUrl: samplePageUrl,
    };

    const experiment = {
        name: experimentName,
        isDesktopEligible: isDesktopEligible,
        isMobileEligible: isMobileEligible,
    };

    return {
        orgId,
        storeId,
        experiment,
        pageSet,
        customerUrl,
    };
};

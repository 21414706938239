import {
    Button,
    FormControl,
    Input,
    Select,
    Spacer,
    Textarea,
    useToast,
    Flex,
    VStack,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Checkbox,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { FieldError, Label, Header, SubHeader } from "../../components";
import { useStore } from "../../hooks";
import { CreateExperimentFormData, CreateExperimentRequest } from "../../types";
import { useCreateExperimentMutation } from "../Editor/hooks";
import { useSuggestions } from "./useSuggestions";
import { validateRegularExpression, validateUrlMatchesRegex, buildCreateExperimentRequest } from "../Editor/lib";
import { pagePatterns } from "../Editor/pagePatternOptions";
import { PageContainer } from "../PageContainer";
import { createPageSetSuggestion } from "./useSuggestions";
import debounce from "lodash.debounce";

const CreateExperimentPage = () => {
    const { data: store } = useStore();

    return (
        <PageContainer>
            <VStack alignItems={"baseline"}>
                <Header>Create A/B Experiment</Header>
                <SubHeader className="trackCustomerUrl">{store?.customerUrl}</SubHeader>
            </VStack>
            <CreateExperienceForm store={store} />
        </PageContainer>
    );
};

const CreateExperienceForm = ({ store }: any) => {
    const { suggestionContext, setSuggestionContext } = useSuggestions();
    const [applyTo, setApplyTo] = useState<string>("");
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<CreateExperimentFormData>({
        defaultValues: initialFormValues,
    });

    const experiment = useCreateExperimentMutation();

    const showToast = useToast({
        position: "bottom",
        duration: 3000,
        isClosable: true,
    });

    const navigate = useNavigate();

    const populateForm = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setApplyTo(value);

        const defaultName: string = pagePatterns.find((option) => option.value === value)?.label ?? "";
        /** @ToDo: When user switches scenario, give a suggestion if appropriate */
        setValue("pagePattern", value);
        setValue("pageSetName", defaultName);
    };

    const suggestPageSet = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        // @ToDo: Validate URL is a valid format
        const url = event.target.value;
        if (!url) return void 0;

        const suggestion = createPageSetSuggestion(url);
        if (suggestion) {
            setSuggestionContext(suggestion);
        }
    };

    const createExperience = async (formData: CreateExperimentFormData) => {
        if (store === undefined) return void 0;
        const request: CreateExperimentRequest = buildCreateExperimentRequest(
            formData,
            store.id,
            store.orgId,
            store.customerUrl,
        );
        const onSuccess = (response: any) => {
            showToast({
                title: "Success",
                description: `"${request.experiment.name}" created`,
                status: "success",
            });

            const { id } = response;
            setTimeout(() => {
                navigate(
                    generatePath(ROUTES.MANAGE_EXPERIMENT, {
                        customerUrl: store.customerUrl,
                        experimentId: id,
                    }),
                );
            }, 1000);
        };

        const onError = () => {
            showToast({
                title: "Error",
                description: "An error occurred while creating the experiment.",
                status: "error",
            });
        };

        await experiment.mutate(request, { onSuccess, onError });
    };

    useEffect(() => {
        if (store) {
            setValue("samplePageUrl", store.customerUrl);
            const suggestion = createPageSetSuggestion(store.customerUrl);
            if (suggestion) {
                setSuggestionContext(suggestion);
            }
        }
    }, [store, setSuggestionContext, setValue]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(initialFormValues);
        }
    }, [isSubmitSuccessful, reset]);

    /**
     * When the suggestionContext changes, update the form values
     * @ToDo: See if we use a "controlled" form if there is a better way to do this -
     *        Or at least move this and the modal markup into another component.
     */
    useEffect(() => {
        if (suggestionContext) {
            const { suggestedPagePattern, pageSetName } = suggestionContext;
            setApplyTo(suggestionContext.pagePattern);
            setValue("pagePattern", suggestedPagePattern);
            setValue("pageSetName", pageSetName);
        }
    }, [suggestionContext, setValue]);

    const applyToLabel = pagePatterns.find((pattern) => pattern.value === applyTo)?.label ?? "Unknown";

    return (
        <FormControl>
            <form onSubmit={handleSubmit(createExperience)}>
                <Label mb={1} htmlFor="experimentName">
                    Name
                </Label>
                <Text mb={2} textStyle={"text-body-meta-regular"}>
                    The name of the experiment.
                </Text>
                <Input
                    type="text"
                    {...register("experimentName", {
                        required: {
                            value: true,
                            message: "Name is required",
                        },
                    })}
                    isInvalid={!!errors.experimentName}
                    id="experimentName"
                />
                <FieldError>{errors.experimentName?.message}</FieldError>

                <Label mb={1} htmlFor="samplePageUrl">
                    URL
                </Label>
                <Text mb={2} textStyle={"text-body-meta-regular"}>
                    The sample page used to specify the content of the experiment.
                </Text>
                <Input
                    type="text"
                    defaultValue={store?.customerUrl || ""}
                    {...register("samplePageUrl", {
                        required: {
                            value: true,
                            message: "Sample URL is required",
                        },
                    })}
                    isInvalid={!!errors.samplePageUrl}
                    onChange={debounce(suggestPageSet, 250)}
                    id="samplePageUrl"
                />
                <FieldError>{errors.samplePageUrl?.message}</FieldError>

                <Text mb={6}>
                    This experiment will be applied to: <strong>{applyToLabel}</strong>
                </Text>

                <VStack spacing={4} align="stretch">
                    <Checkbox {...register("isDesktopEligible")} id="isDesktopEligible">
                        Desktop Eligible
                    </Checkbox>

                    <Checkbox {...register("isMobileEligible")} id="isMobileEligible">
                        Mobile Eligible
                    </Checkbox>
                </VStack>

                <Accordion allowMultiple mt={"spacer-1"} mb={"spacer-12"}>
                    <AccordionItem border={"none"}>
                        <AccordionButton pl={0}>
                            <AccordionIcon />
                            <Text textStyle={"text-body-regular"} fontWeight={"bold"}>
                                Advanced
                            </Text>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Label mb={1} htmlFor="applyTo">
                                Apply to
                            </Label>
                            <Text mb={2} textStyle={"text-body-meta-regular"}>
                                <i>In most cases, this is determined by the URL above.</i>
                            </Text>
                            <Select
                                placeholder="Select option"
                                name="applyTo"
                                id="applyTo"
                                value={applyTo}
                                onChange={populateForm}
                                isRequired={true}
                            >
                                {pagePatterns.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                            <FieldError> </FieldError>

                            <Label mb={1} htmlFor="pageSetName">
                                Pageset Name
                            </Label>
                            <Text mb={2} textStyle={"text-body-meta-regular"}>
                                <i>In most cases, use the suggested value.</i>
                            </Text>
                            <Input
                                type="text"
                                defaultValue={suggestionContext.pageSetName}
                                {...register("pageSetName", {
                                    required: {
                                        value: true,
                                        message: "Name is required",
                                    },
                                })}
                                isInvalid={!!errors.pageSetName}
                                id="pageSetName"
                            />
                            <FieldError>{errors.pageSetName?.message}</FieldError>

                            <Label htmlFor="pagePattern">Pageset Regular Expression</Label>
                            <Text mb={2} textStyle={"text-body-meta-regular"}>
                                <i>In most cases, use the suggested value.</i>
                            </Text>
                            <Textarea
                                h="150px"
                                {...register("pagePattern", {
                                    required: true,
                                    validate: {
                                        validRegex: validateRegularExpression,
                                        urlMatchesRegex: validateUrlMatchesRegex(getValues("samplePageUrl")),
                                    },
                                })}
                                isInvalid={!!errors.pagePattern}
                                id="pagePattern"
                            />
                            <FieldError>{errors.pagePattern?.message}</FieldError>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Input type="hidden" value={initialFormValues.pageType} {...register("pageType")} id="pageType" />
                <Flex pb="spacer-6">
                    <Spacer />
                    <Button colorScheme={"button-primary"} type="submit">
                        Create
                    </Button>
                </Flex>
            </form>
        </FormControl>
    );
};

export default CreateExperimentPage;

const initialFormValues = {
    experimentName: "",
    pageSetName: "",
    samplePageUrl: "",
    pageType: "experiment",
    pagePattern: "",
    applyTo: "",
    isDesktopEligible: true,
    isMobileEligible: true,
};

import { useToast, Flex } from "@chakra-ui/react";
import { useDeleteExperimentMutation, useEnqueueDeploymentMutation } from "../../hooks";
import { useNavigate, generatePath } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { IconButtonDelete, IconButtonEdit, Tooltip } from "../../components";

type ExperimentRowProps = {
    experimentId: string;
    customerUrl: string;
};

export const ExperimentRowActions = ({ experimentId, customerUrl }: ExperimentRowProps) => {
    const deleteExperimentMutation = useDeleteExperimentMutation();
    const showToast = useToast();
    const navigate = useNavigate();
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });

    const onSuccess = () => {
        showToast({
            title: "Success",
            description: "Experiment Deleted Successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    const onError = (error: any) => {
        showToast({
            title: error.name || "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    };

    const deleteExperiment = () => {
        if (experimentId === undefined) return;
        enqueueDeployment.mutate(customerUrl);
        const result = window.confirm("Are you sure you want to delete this experiment?");
        if (result) {
            deleteExperimentMutation.mutate(
                { experimentId, customerUrl },
                {
                    onSuccess,
                    onError,
                },
            );
        }
        return void 0;
    };

    return (
        <Flex>
            <Tooltip label="Delete Experiment" aria-label="Delete Experiment">
                <IconButtonDelete onClick={deleteExperiment} area-label={"Delete Experiment"} />
            </Tooltip>
            <Tooltip label="Edit Experiment" aria-label="Edit Experiment">
                <IconButtonEdit
                    onClick={() =>
                        navigate(
                            generatePath(ROUTES.MANAGE_EXPERIMENT, {
                                customerUrl,
                                experimentId,
                            }),
                        )
                    }
                    aria-label={"Edit Experiment"}
                />
            </Tooltip>
        </Flex>
    );
};

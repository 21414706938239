import { Divider } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../../../components";
import { ReportParameters } from "./ReportParameters";
import { OrderTaggingCoverageByInterval } from "./OrderTaggingCoverageByInterval";
import { OrderTaggingCoverage } from "./OrderTaggingCoverage";
import { SessionCount } from "./SessionCount";
import { AbsoluteCumulativeSessionCount } from "./AbsoluteCumulativeSessionCount";
import { RelativeCumulativeSessionCount } from "./RelativeCumulativeSessionCount";
import { CVR } from "./CVR";
import { AOVOriginal } from "./AOVOriginal";
import { RPSOriginal } from "./RPSOriginal";
import { AOVDiscount } from "./AOVDiscount";
import { RPSDiscount } from "./RPSDiscount";
import { CVRByQuantile } from "./CVRByQuantile";
import { ConfidenceRange } from "./ConfidenceRange";
import { Humps } from "./Humps";

export const RevenueReport = () => {
    const [searchParams] = useSearchParams();
    const enabled =
        searchParams.get("from_hour") !== null &&
        searchParams.get("upto_hour") !== null &&
        searchParams.get("sale_strategy") !== null &&
        searchParams.get("percentile") !== null;

    return (
        <>
            <ReportParameters />
            {enabled && (
                <>
                    <Header style={{ marginTop: "20px" }}>Hump Chart</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <Humps />
                    <Header style={{ marginTop: "20px" }}>Two Arm Experiment</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <ConfidenceRange />
                    <Header style={{ marginTop: "20px" }}>[Check] Cart Tagging (Order Attribution)</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <OrderTaggingCoverageByInterval />
                    <OrderTaggingCoverage />
                    <Header style={{ marginTop: "20px" }}>
                        [Check] Session Splitter (Asymmetric Session Generation)
                    </Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <SessionCount />
                    <AbsoluteCumulativeSessionCount />
                    <RelativeCumulativeSessionCount />
                    <Header style={{ marginTop: "20px" }}>[Customer] CVR</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <CVR />
                    <Header style={{ marginTop: "20px" }} as="h3" textStyle="text-header-L">
                        Original
                    </Header>
                    <AOVOriginal />
                    <RPSOriginal />
                    <Header style={{ marginTop: "20px" }} as="h3" textStyle="text-header-L">
                        Original - Discount
                    </Header>
                    <AOVDiscount />
                    <RPSDiscount />

                    <Header style={{ marginTop: "20px" }} as="h3" textStyle="text-header-L">
                        Quantile Discovery
                    </Header>
                    <CVRByQuantile />
                </>
            )}
        </>
    );
};

import { useState } from "react";
import { extractPathname, parseSlugValue } from "../Editor/lib";
import { pagePatterns } from "../Editor/pagePatternOptions";

export type SuggestionContext = {
    pagePattern: string;
    pageSetName: string;
    suggestedPagePattern: string;
};

export const useSuggestions = () => {
    const emptySuggestionContext = {
        pagePattern: "",
        pageSetName: "",
        url: "",
        suggestedPagePattern: "",
    };

    const [suggestionContext, setSuggestionContext] = useState<SuggestionContext>(emptySuggestionContext);
    return { suggestionContext, setSuggestionContext };
};

export const createPageSetSuggestion = (url: string): SuggestionContext | undefined => {
    /** Scenario: Homepage */
    if (isHomepage(url)) {
        const pagePattern = pagePatterns.find((option) => option.label === "Homepage");

        if (pagePattern !== undefined) {
            const suggestedName = `${pagePattern.label}`;
            const suggestedPagePattern = pagePattern.value;

            return {
                pagePattern: pagePattern.value,
                pageSetName: suggestedName,
                suggestedPagePattern,
            };
        }
    }

    /** Scenario: Collection Handle */
    const collectionHandle = parseSlugValue(url, "/collections/:collectionHandle");
    if (collectionHandle) {
        const pagePattern = pagePatterns.find((option) => option.label === "Specific collection page");
        if (pagePattern) {
            const suggestedName = `${pagePattern.label} - ${collectionHandle}`;
            const suggestedPagePattern = pagePattern.value.replace("X", collectionHandle);

            return {
                pagePattern: pagePattern.value,
                pageSetName: suggestedName,
                suggestedPagePattern,
            };
        }
    }

    /** Scenario: Product Handle */
    const productHandle = parseSlugValue(url, ["/products/:productHandle", "/collections/*/products/:productHandle"]);
    if (productHandle) {
        const pagePattern = pagePatterns.find((option) => option.label === "Specific product page");

        if (pagePattern !== undefined) {
            const suggestedName = `${pagePattern.label} - ${productHandle}`;
            const suggestedPagePattern = pagePattern.value.replace("X", productHandle);

            return {
                pagePattern: pagePattern.value,
                pageSetName: suggestedName,
                suggestedPagePattern,
            };
        }
    }
};

const isHomepage = (url: string) => {
    const pathname = extractPathname(url);
    const pattern = new URLPattern({ pathname: "/" });
    return pattern.test({ pathname });
};

import { UseQueryOptions } from "@tanstack/react-query";
import { LogType } from "./forms";

export type JSONString = string;
export type URLPath = string;
export type CacheDurationPrefix = {
    prefix: URLPath;
    duration: number;
};

export type CacheDurationMap = {
    __global__: number;
    root?: number;
    prefixes?: CacheDurationPrefix[];
};

export type Store = {
    id: string;
    orgId: string;
    org: StoreOrgRelationship;
    name: string;
    updatedAt?: string;
    customerUrl: string;
    ecomUrl: string;
    cacheDurationMap: CacheDurationMap;
    currencyDefault: string;
    edgeEnabled: boolean;
    edgeEnabledAt: string | null;
    edgeMemberEnabled: boolean;
    personalizationEnabled: boolean;
    personalizationEnabledAt: string | null;
    staticSiteEnabled: boolean;
    staticSiteEnabledAt: string | null;
    sessionContent: JSONString;
    sessionContentTestCode: JSONString;
    currencyMap: JSONString;
    zoneId: string;
    // shopify
    shopifyApiKey: string;
    shopifyAuthToken: string;
    shopifySecretKey: string;
    shopifyStorefrontToken: string;
    orderSyncEnabled: boolean;
    orderSyncCursor: string | null;
    shopifyTimeZone: TimeZoneData | null;
    // worker specific
    workerBranch: string;
    workerPlatform: "salesforce" | "shopify";
    workerRoutes: Record<string, string>;
    // url classification
    classificationCacheable: ClassificationRule[];
    classificationDocument: ClassificationRule[];
    classificationProtected: ClassificationRule[];
    // salesforce specific
    salesforceApiClientId: string;
    salesforceApiClientSecret: string;
    salesforceOrgId: string;
    salesforceShortCode: string;
    salesforceSiteId: string;
    salesforceTenantId: string;
    // hints
    hints: Hints;
    mode: StoreModes;
    testModePath: string;
    // API token
    clientApiToken: string;
    // AB Test
    speedABTestStartedAt: string | null;
    speedABTestEndedAt: string | null;
    logs: LogHistory;
    countryDefault: string | null;
    logLevel: string;
    ignoreUtmParameters: boolean;
};

type StoreOrgRelationship = Pick<Organization, "id" | "name">;

export type Organization = {
    id: string;
    name: string;
    onboardingEmail: string;
    offboardingDate: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
    ext: string | null;
    active: boolean;
};

export type ClassificationRule = { type: string; pattern: string };

type ActionId = string | undefined;
export type Action = {
    id?: ActionId;
    action?: string;
    replaceSelector: string;
    insertText: string;
    deleted?: boolean;
};

export type ExperimentAction = {
    id?: string;
    replaceSelector: string;
    action: string; // @ToDo: define this type so that it can auto-complete the valid values (insert, replace, etc.)
    insertText: string;
};

export type ExperimentVariation = {
    actions: ExperimentAction[];
} & Variation;

export type Variation = {
    id?: string;
    experimentId: string;
    weight: number;
    name: string;
    order: number;
    actions: Action[];
    deleted?: boolean;
};

export type SessionContent = {
    id: string;
    name: string;
    pageset: Pick<PageSet, "name" | "pagePattern">;
    variations: Omit<Variation, "experimentId">[];
};

export type PageSet = {
    id: string;
    name: string;
    pagePattern: string;
    sampleUrl: string;
    pageType: string;
    storeId: string;
    orgId: string;
    ext: string | null;
    createdAt: string;
    updatedAt: string;
};

export interface Hints {
    [key: string]: Hint[] | undefined;
}

export interface Hint {
    type: string;
    value: string;
    as: string;
}

export type TextRecord = {
    name: string;
    value: string;
};

export type Experiment = {
    id: string;
    orgId: string;
    name: string;
    description: string;
    createdAt?: string;
    active?: boolean;
    variations: ExperimentVariation[];
    pageset: PageSet;
    isMobileEligible: boolean;
    isDesktopEligible: boolean;
};

export type ExperimentResponse = Omit<Experiment, "pageset"> & {
    pageset: PageSet;
};

export type EnqueueDeploymentOptions = {
    onDeploymentStart?: () => void;
    onDeploymentSuccess?: () => void;
    onPollingStart?: () => void;
    onPollingEnd?: () => void;
    polling?: boolean;
    showToast?: boolean;
};

export type QueryOptions =
    | Omit<UseQueryOptions<any, unknown, any, string[]>, "initialData" | "queryFn" | "queryKey">
    | undefined;

export type DeviceName = "Desktop" | "Mobile" | "iPhone SE" | "Pixel 7" | "iPhone 14" | "iPad Air" | "Galaxy S22 ultra";

export type Viewport = {
    name: DeviceName;
    width: string;
    height: string;
};

export type ScheduledPurge = {
    storeUrl: string;
    date: string;
    pattern?: string;
};

export type ScheduledPurgeRow = {
    storeUrl: string;
    date: string;
    id: string;
    pattern?: string;
};

export type User = {
    id: string;
    email: string;
    phone: string;
    name: string;
    orgId: string;
    role: string;
    active?: boolean;
    createdAt: string;
    updatedAt: string;
};

export type CacheHistoryRow = {
    id: string;
    requestedBy: string;
    date: string;
    status: string;
    metadata: Metadata;
};

export type PendingCacheRow = {
    id: string;
    requestedBy: string;
    date: string;
    status: string;
    customerUrl: string;
    metadata: Metadata;
};

export type PendingRecurringCacheItem = {
    end: string | null;
    interval: number;
    id: string;
    metadata: Metadata | null;
    next: string;
    requestedBy: string;
    start: string;
};

type Metadata = {
    pathNames: string[];
};

export type StoreHistoryLogs = {
    id: string;
    entry: string;
    createdAt: string;
    createdBy: string;
};

export type StoreHistoryRow = {
    id: string;
    date: string;
    user: string;
    status: StoreModes;
    reason: string;
    entry: string;
};

export enum StoreModes {
    Enabled = "enabled",
    Testing = "testing",
    Disabled = "disabled",
    DisabledByProvider = "disabledByProvider",
    DnsSetup = "dnsSetup",
    Unknown = "Unknown",
}

export type Organizations = Organization[];

export type Webhooks = {
    id: string;
    topic: string;
    created_at: string;
};

export type Bulletin = {
    id: string;
    type: BulletinType;
    title: string;
    description: string;
    publishAt: string | null;
    unpublishAt: string | null;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};

export type BulletinType = "info" | "warning" | "danger";

export enum DurationUnitType {
    Second = "second",
    Minute = "minute",
    Hour = "hour",
    Day = "day",
    Week = "week",
}

export type ApiTokenHistoryRow = {
    date: string;
    user: string;
    message: string;
};

export type HistoryRow = {
    date: string;
    user: string;
    field: string;
    oldValue: string;
    newValue: string;
};

export enum HistoryFields {
    Date = "date",
    User = "user",
    Field = "field",
    OldValue = "old value",
    NewValue = "new value",
}
export type LogHistory = {
    updatedAt?: string;
    createdBy: string;
    entry: string;
    createdAt?: string;
    id?: string;
    storeId?: string;
};

export type GlobalHistoryLogRow = {
    date: string;
    user: string;
    table: string;
    type: LogType;
    data: DataField;
};
type DataField = {
    [key: string]: string;
};

export type EcomStore = Pick<Store, "id" | "customerUrl"> & { deleted?: boolean };
export type ShopData = Pick<Store, "name" | "customerUrl" | "mode">;

export type EcomStoreApp = {
    store: EcomStore;
    deleted: boolean;
};

export type SingleShopifyApp = {
    id: string;
    title: string;
    developerName: string;
    description: string;
    iconUrl: string;
    features?: string[];
    handle: string;
    notes?: string;
    supported: boolean;
    EcomStoreApp: EcomStoreApp[];
};

export type InstalledApps = SingleShopifyApp[];

export type UpdatedAppInfo = Pick<SingleShopifyApp, "notes" | "supported">;

export type ShopifyApp = {
    deleted: boolean;
    app: {
        id: string;
        title: string;
        developerName: string;
        description: string;
        iconUrl: string;
        handle: string;
        supported: boolean;
    };
};

export type ShopifyApps = ShopifyApp[];

export type TimeZoneData = {
    ianaTimezone: string;
    timezoneAbbreviation: string;
};

export type TabPaths = {
    [key: string]: string;
};

export type AllStoresReportData = {
    storeHostname: string;
    crawlerOptimizationEnabled: boolean;
    crawlerOptimizationEnabledAt: string | null | undefined;
    ecomUrl: string;
    edgeEnabled: boolean;
    edgeEnabledAt: string | null | undefined;
    experimentationEnabled: boolean;
    experimentationStartDate: string | null;
    lastOrderSeen: string | null;
    orderSyncCursor: string | null;
    orderSyncEnabled: boolean;
    sessionsMonth?: number;
    sessionsWeek?: number;
    sessionsQuarter?: number;
    sessionsYear?: number;
    weightEdge: number | null;
    weightOrigin: number | null;
};

import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { CreateVariantRequest } from "../../types";

export const useCreateVariantMutation = (customerUrl: string) => {
    return useMutation((request: CreateVariantRequest) => createVariant(request, customerUrl));
};

const createVariant = async (request: CreateVariantRequest, customerUrl: string) => {
    const { experimentId } = request;
    const CREATE_VARIANT = `${SVC_URLS["experiment"]}/experiment/${experimentId}/variation`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(CREATE_VARIANT, options).catch(handleErrors);

    return fetchResponse(response);
};

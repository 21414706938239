import {
    Button,
    Flex,
    FormControl,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Spacer,
    useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams, createSearchParams } from "react-router-dom";
import { Header, SubHeader, FieldError, Label } from "../components";
import { useCreateStoreMutation, useOrg } from "../hooks";
import { asCustomerUrl } from "../lib";
import { ROUTES } from "../Routes";
import { CreateStoreRequest, CreateStoreResponse } from "../types";
import { NotFound404 } from "./ErrorPages/NotFound404";
import { LoadingPage } from "./LoadingPage";
import { PageContainer } from "./PageContainer";

// Component accessed from organization page
export const CreateStorePage = () => {
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        setValue,
    } = useForm<CreateStoreFormData>({
        defaultValues: initialFormValues,
        mode: "onBlur",
    });
    const { orgId } = useParams<{ orgId: string }>();
    const createStoreMutation = useCreateStoreMutation();
    const { data: organization, isLoading } = useOrg(orgId);
    const [ecomUrlPlaceholder, setEcomUrlPlaceholder] = useState("production-nostra.myshopify.com");
    const showToast = useToast();
    const navigate = useNavigate();

    const applyStoreUrlMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedInput = asCustomerUrl(e.target.value);
        const name = e.target.name as keyof CreateStoreFormData;
        setValue(name, maskedInput, { shouldValidate: true });
    };

    const onSuccess = ({ store, txtRecord }: CreateStoreResponse, _variables: CreateStoreRequest) => {
        if (orgId === undefined || store.id === undefined) {
            throw new Error("Org ID and Store ID are required");
        }

        showToast({
            title: "Success",
            description:
                "You have successfully created a new customer. Please copy the DNS TXT record and add it to your domain's DNS records.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        reset(initialFormValues);

        // Redirect to confirmation page
        const { zoneId, customerUrl } = store;
        navigate({
            pathname: generatePath(ROUTES.CREATE_STORE_CONFIRMATION, {
                customerUrl,
                orgId,
            }),
            search: createSearchParams({
                zoneId,
                name: txtRecord.name,
                value: txtRecord.value,
            }).toString(),
        });
    };

    const onError = (error: any) => {
        showToast({
            title: error.name || "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    };

    const onSubmit: SubmitHandler<CreateStoreFormData> = async (formData: CreateStoreFormData) => {
        if (orgId === undefined) return;
        const request: CreateStoreRequest = {
            name: formData.storeName,
            customerUrl: formData.customerUrl,
            ecomUrl: formData.ecomUrl,
            workerPlatform: formData.workerPlatform,
            orgId,
        };

        createStoreMutation.mutate(request, {
            onError,
            onSuccess,
        });
    };

    if (isLoading) return <LoadingPage />;
    if (organization === undefined) return <NotFound404 />;

    return (
        <PageContainer>
            <Header>Create New Store</Header>
            <SubHeader>{organization.name}</SubHeader>
            <FormControl maxWidth={{ md: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <>
                        <Label htmlFor="workerPlatform">Platform</Label>
                        <Select
                            {...register("workerPlatform")}
                            name="workerPlatform"
                            id="workerPlatform"
                            defaultValue="shopify"
                            onChange={(e) => setEcomUrlPlaceholder(deriveEcomUrlPlaceholder(e.target.value))}
                        >
                            <option value="shopify">Shopify</option>
                            <option value="salesforce">Salesforce</option>
                        </Select>
                        <FieldError> </FieldError>
                    </>
                    <Label htmlFor="storeName">Store Name</Label>
                    <Input
                        type="text"
                        id="storeName"
                        {...register("storeName", {
                            required: {
                                value: true,
                                message: "Name is required",
                            },
                        })}
                        isInvalid={!!errors.storeName}
                    />
                    <FieldError>{errors.storeName?.message}</FieldError>

                    <Label htmlFor="customerUrl">Customer URL</Label>
                    <InputGroup>
                        <InputLeftAddon children="https://" />
                        <Input
                            type="text"
                            id="customerUrl"
                            placeholder="www.speed-preview.com"
                            {...register("customerUrl", {
                                required: {
                                    value: true,
                                    message: "Customer URL is required",
                                },
                            })}
                            onChange={applyStoreUrlMask}
                            isInvalid={!!errors.customerUrl}
                        />
                    </InputGroup>
                    <FieldError>{errors.customerUrl?.message}</FieldError>

                    <Label htmlFor="ecomUrl">Ecom URL</Label>
                    <InputGroup>
                        <InputLeftAddon children="https://" />
                        <Input
                            type="text"
                            id="ecomUrl"
                            placeholder={ecomUrlPlaceholder}
                            {...register("ecomUrl", {
                                required: {
                                    value: true,
                                    message: "Ecom URL is required",
                                },
                            })}
                            onChange={applyStoreUrlMask}
                            isInvalid={!!errors.ecomUrl}
                        />
                    </InputGroup>
                    <FieldError>{errors.ecomUrl?.message}</FieldError>

                    <Flex pb="spacer-6">
                        <Spacer />
                        <Button
                            isLoading={createStoreMutation.isLoading}
                            loadingText="Creating Store"
                            type="submit"
                            colorScheme="button-primary"
                        >
                            Create Store
                        </Button>
                    </Flex>
                </form>
            </FormControl>
        </PageContainer>
    );
};

const initialFormValues: CreateStoreFormData = {
    storeName: "",
    customerUrl: "",
    ecomUrl: "",
    orgId: "",
    workerPlatform: "shopify",
};

type CreateStoreFormData = {
    storeName: string;
    customerUrl: string;
    ecomUrl: string;
    orgId: string;
    workerPlatform: "salesforce" | "shopify";
};

const deriveEcomUrlPlaceholder = (workerPlatform: string) => {
    switch (workerPlatform) {
        case "salesforce":
            return "commcloud.dev-bktf-sfccspeed-com.cc-ecdn.net";
        case "shopify":
            return "production-nostra.myshopify.com";
        default:
            return "";
    }
};

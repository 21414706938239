import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../../../config/SVC_URLS";
import { fetchOptions } from "../../../../lib/http/fetchOptions";
import { fetchResponse } from "../../../../lib/http/fetchResponse";
import { handleErrors } from "../../../../lib/http/handleErrors";
import { CreateExperimentRequest } from "../../../../types";

const CREATE_EXPERIMENT_URL = `${SVC_URLS["experiment"]}/experiment`;

export const useCreateExperimentMutation = () => useMutation({ mutationFn: createExperiment });

const createExperiment = async (experiment: CreateExperimentRequest) => {
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(experiment),
        headers: { Store: experiment.customerUrl },
    });

    const response = await fetch(CREATE_EXPERIMENT_URL, options).catch(handleErrors);

    return fetchResponse(response);
};

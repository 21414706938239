import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

// auto has better UI for horizontal scrolling
type TableShift = "auto" | "fixed";

export type DataTableProps<Data extends object> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
    tableShift?: TableShift;
};

export function VirtualizedDataTable<Data extends object>({
    data,
    columns,
    tableShift = "fixed",
}: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });
    const parentRef = React.useRef<HTMLDivElement>(null);
    const rowHeight = 73;

    const virtualizer = useVirtualizer({
        count: data?.length ?? 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => rowHeight,
        overscan: 10,
    });

    return (
        <div
            ref={parentRef}
            style={{
                height: data?.length > 10 ? "calc(100vh - 200px)" : "auto",
                overflow: "auto",
            }}
        >
            <Table layout={tableShift === "fixed" ? { md: "auto", lg: "fixed" } : tableShift}>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                const meta: any = header.column.columnDef.meta;
                                return (
                                    <Th
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        isNumeric={meta?.isNumeric}
                                        paddingInlineStart={2}
                                        paddingInlineEnd={2}
                                        fontFamily="Inter"
                                        fontSize={{ base: "0.75rem", md: "1rem" }}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}

                                        <chakra.span pl="4">
                                            {header.column.getIsSorted() ? (
                                                header.column.getIsSorted() === "desc" ? (
                                                    <TriangleDownIcon aria-label="sorted descending" />
                                                ) : (
                                                    <TriangleUpIcon aria-label="sorted ascending" />
                                                )
                                            ) : null}
                                        </chakra.span>
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {virtualizer.getVirtualItems().map((virtualRow, index) => {
                        const row = table.getRowModel().rows[virtualRow.index];
                        return (
                            <Tr
                                key={row.id}
                                style={{
                                    height: `${virtualRow.size}px`,
                                    transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                                    backgroundColor:
                                        virtualRow.index % 2 === 0 ? "var(--chakra-colors-gray-100)" : "white",
                                }}
                            >
                                {table.getHeaderGroups()[0].headers.map((header) => {
                                    const cell = row.getAllCells().find((data) => data.column.id === header.id);
                                    if (!cell) return null;
                                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type thiscorrectly
                                    const meta: any = cell.column.columnDef.meta;
                                    return (
                                        <Td
                                            key={cell.id}
                                            isNumeric={meta?.isNumeric}
                                            paddingInlineStart={2}
                                            paddingInlineEnd={2}
                                            fontSize={{ base: "0.875rem", md: "1.05rem" }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </div>
    );
}

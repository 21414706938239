import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Button,
    Input,
    Flex,
    Spacer,
    Box,
    useToast,
    HStack,
    Text,
    DrawerCloseButton,
    Divider,
} from "@chakra-ui/react";
import { Variation, UpdateWeightRequest } from "../../types";
import {
    useUpdateWeightMutation,
    generateExperimentQueryKey,
    useEnqueueDeploymentMutation,
    useMotionProps,
} from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FieldError, Header } from "../../components";
import { useEffect, useState } from "react";
import { percentToDecimal, sum, asPercent, toNumber } from "../../lib";

const calculateTotalWeights = (variants: Variation[]): number =>
    Number(
        variants
            .map((variant) => variant.weight)
            .reduce(sum, 0)
            .toFixed(2),
    );

type Props = {
    isOpen: boolean;
    onClose: () => void;
    experimentId: string;
    variants: Variation[];
    customerUrl: string;
};

const initialFormValues = {};

export const EditWeightDrawer = ({ isOpen, onClose, experimentId, variants, customerUrl }: Props) => {
    const updateWeightsMutation = useUpdateWeightMutation(customerUrl);
    const showToast = useToast();
    const queryClient = useQueryClient();
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });
    const motionProps = useMotionProps();

    const {
        handleSubmit,
        reset,
        register,
        watch,
        formState: { errors },
    } = useForm<NewWeightsFormData>({
        defaultValues: initialFormValues,
    });

    const onSuccess = () => {
        enqueueDeployment.mutate(customerUrl);
        queryClient.invalidateQueries(generateExperimentQueryKey(experimentId));
        showToast({
            title: "Success",
            description: "Weight Updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        reset(initialFormValues);
        onClose();
    };

    const onError = (error: any) => {
        showToast({
            title: error.name || "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    };

    const onSubmit = async (formData: NewWeightsFormData) => {
        const request: UpdateWeightRequest = buildUpdateWeightRequest(formData);

        updateWeightsMutation.mutate(request, {
            onSuccess,
            onError,
        });
    };

    const [total, setTotal] = useState(0);

    useEffect(() => {
        setTotal(calculateTotalWeights(variants));
    }, [isOpen, variants]);

    useEffect(() => {
        const subscription = watch((formData) => {
            const result = Number(
                Object.values(formData).map(toNumber).map(percentToDecimal).reduce(sum, 0).toFixed(2),
            );

            setTotal(result);
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <Drawer
            isOpen={isOpen}
            onCloseComplete={() => reset()}
            placement="right"
            onClose={onClose}
            size={{ base: "xs", md: "sm" }}
        >
            <DrawerOverlay />
            <DrawerContent motionProps={motionProps}>
                <Flex align="center" m={3}>
                    <DrawerCloseButton position="static" />
                    <Header textStyle="text-header-M" ml={3}>
                        Edit weights
                    </Header>
                    <Spacer />
                    <Button
                        size={"sm"}
                        isDisabled={total !== 1 || updateWeightsMutation.isLoading}
                        form="weightForm"
                        type="submit"
                        colorScheme="button-primary"
                    >
                        Save
                    </Button>
                </Flex>
                <Divider />
                <DrawerBody>
                    <Text fontSize="md">
                        How would you like to distribute the proportion of traffic amongst variations?
                    </Text>
                    <Flex>
                        <Box color="gray.500" margin="3" ml={0}>
                            Variation
                        </Box>
                        <Spacer />
                        <Box color="gray.500" margin="3">
                            Weight
                        </Box>
                    </Flex>
                    <form onSubmit={handleSubmit(onSubmit)} id="weightForm">
                        {variants.map((variant) => {
                            if (!variant.id) return void 0;

                            return (
                                <HStack mt={2} key={variant.id}>
                                    <label htmlFor={variant.id}>{variant.name}</label>
                                    <Spacer />
                                    <Input
                                        width={"65px"}
                                        type="number"
                                        placeholder="0"
                                        defaultValue={asPercent(variant.weight, false)}
                                        {...register(variant.id, {
                                            required: {
                                                value: true,
                                                message: "Variation ID is required",
                                            },
                                        })}
                                    />
                                    <Box>%</Box>
                                    <FieldError>{errors[variant.id]?.message}</FieldError>
                                </HStack>
                            );
                        })}
                    </form>
                    <Divider orientation="horizontal" height={14} />
                    <Flex>
                        <Spacer />
                        <Box mt="2" mr="2" color={"gray.500"}>
                            {asPercent(total)}
                        </Box>
                    </Flex>
                    <Flex>
                        <Spacer />
                        <Box color={"red"}>{total !== 1 ? "Total must add to 100%!" : null}</Box>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

const buildUpdateWeightRequest = (formData: NewWeightsFormData): UpdateWeightRequest => {
    let result: Array<Pick<Variation, "id" | "weight">> = [];

    Object.entries(formData).forEach(([key, value]) => {
        result.push({ id: key, weight: percentToDecimal(value) });
    });

    if (!result) {
        throw new Error("No variants found");
    }
    return { weights: result };
};

type NewWeightsFormData = {
    [key: string]: number;
};

import {
    Button,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton,
    Flex,
    useToast,
    Spacer,
    DrawerBody,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Divider,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useUpdateVariantNameMutation, useMotionProps } from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { Variation } from "../../types";
import { useEffect } from "react";
import { Header, FieldError } from "../../components";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    variant: Variation;
    experimentId: string;
    customerUrl: string;
};

export const UpdateVariantNameDrawer = ({ isOpen, onClose, variant, experimentId, customerUrl }: Props) => {
    const updateVariantNameMutation = useUpdateVariantNameMutation();
    const showToast = useToast();
    const queryClient = useQueryClient();
    const motionProps = useMotionProps();

    const {
        handleSubmit,
        reset,
        register,
        formState: { errors },
    } = useForm<NewVariantNameFormData>();

    useEffect(() => {
        if (isOpen) {
            reset({
                id: variant.id!,
                name: variant.name,
            });
        }
    }, [isOpen, reset, variant.id, variant.name]);

    const onSubmit = async (data: NewVariantNameFormData) => {
        const request = buildUpdateVariantNameRequest(data, variant.id!, customerUrl);

        updateVariantNameMutation.mutate(request, {
            onSuccess: () => {
                queryClient.invalidateQueries(["experiment", experimentId]);
                showToast({
                    title: "Success",
                    description: "Variation name updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                reset(initialFormValues);
                onClose();
            },
            onError: (error: any) => {
                showToast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            },
        });
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={{ base: "xs", md: "sm" }}>
            <DrawerOverlay />
            <DrawerContent motionProps={motionProps}>
                <Flex align="center" m={3}>
                    <DrawerCloseButton position="static" />
                    <Header textStyle={"text-header-M"} ml={3}>
                        Edit Name
                    </Header>
                    <Spacer />
                    <Button size={"sm"} form="nameForm" type="submit" colorScheme="button-primary">
                        Save
                    </Button>
                </Flex>
                <Divider />
                <DrawerBody>
                    <Stack spacing={6}>
                        <form id="nameForm" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isRequired>
                                <FormLabel>Variation Name</FormLabel>
                                <Input
                                    width="100"
                                    type="text"
                                    placeholder="Variation Name"
                                    defaultValue={variant.name}
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "Variation name is required",
                                        },
                                    })}
                                />
                                <FieldError>{errors.name?.message}</FieldError>
                            </FormControl>
                        </form>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

const buildUpdateVariantNameRequest = (data: NewVariantNameFormData, variantId: string, customerUrl: string) => {
    return {
        id: variantId,
        name: data.name,
        customerUrl,
    };
};

type NewVariantNameFormData = {
    id: string;
    name: string;
};

const initialFormValues: NewVariantNameFormData = {
    id: "",
    name: "",
};

import { useSearchParams } from "react-router-dom";
import { useRevenueReport } from "../../../../hooks";
import { IntervalForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const AOVOriginal = () => {
    const [searchParams] = useSearchParams();
    const report = "normalized_field_split";
    const metric = "aov";
    const sale_strategy = "original";
    const prefix = `${metric}_${sale_strategy}_${report}`;

    const show_text =
        searchParams.get(`${report}_show_text`) === null || searchParams.get(`${report}_show_text`) === "true"
            ? true
            : false;

    const show_labels = searchParams.get(`${report}_show_labels`) === "true" ? true : false;

    const metricParams = {
        metric,
        sale_strategy,
        interval_unit: searchParams.get(`${prefix}_interval_unit`) ?? "days",
        interval_count: searchParams.get(`${prefix}_interval_count`) ?? 365,
        show_text,
        show_labels,
    };

    const { data, isLoading, isError } = useRevenueReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <IntervalForm prefix={prefix} params={metricParams} />
        </RenderMetric>
    );
};

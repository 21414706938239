export const ROUTES = {
    HOME: "/",
    LIST_STORES: "/stores",
    SHOP: "/shop",
    STORE_EDIT: "/store/:customerUrl",
    STORE_REPORTS: "/store/:customerUrl/reports",
    ALL_STORES_REPORT: "/reports/all-stores",
    CREATE_CUSTOMER: "/create-customer",
    CREATE_EXPERIMENT: "/store/:customerUrl/create-experiment",
    CREATE_VARIANT_CONTENT: "/store/:customerUrl/experiment/:experimentId/variant/:variantId/editor",
    LIST_EXPERIMENTS: "/stores/:customerUrl/experiments",
    EXPERIMENT: "/experiments/:experimentId/variations",
    MANAGE_EXPERIMENT: "/stores/:customerUrl/experiments/:experimentId/manage",
    CONFIRMATION_PAGE: "/confirmation/:storeId",
    DEPRECATED_CLEAR_CACHE_PAGE: "/clear-cache/:storeId/:customerUrl",
    MANAGE_CACHE_AUTHENTICATED: "/cache/:customerUrl",
    ORGANIZATION: "/organizations/:orgId",
    DISABLED_ERROR_PAGE: "/stores/:storeId/disabled",
    CREATE_STORE: "/organizations/:orgId/store",
    CREATE_STORE_CONFIRMATION: "/organizations/:orgId/store/:customerUrl/confirmation",
    MOBILE_PREVIEW: "/experiment/:experimentId/variation/:variationId/preview/mobile",
    SUPPORT: "https://nostra-corporate.webflow.io/contact-nostra-support",
    LIST_PURGE_CACHE: "/:storeId/purge/enqueue",
    LIST_USERS: "/users",
    ALL_ORGANIZATIONS: "/organizations",
    LIST_BULLETINS_PAGE: "/bulletins",
    CREATE_BULLETIN: "/bulletins/new",
    BULLETIN_EDIT: "/bulletins/:bulletinId/edit",
    INSTALLED_APPS: "/installed-apps",
    SHOPIFY_APP: "/shopify-apps/:appId",

    // Documentation routes
    DOCUMENTATION: "/getting-started/welcome-to-nostra",
    DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL: "/getting-started/tour-the-nostra-portal",
    DOCUMENTATION_INITIAL_TIPS: "/getting-started/initial-tips",
    DOCUMENTATION_WHEN_TO_CONTACT_NOSTRA: "/getting-started/when-to-contact-nostra-with-updates",
    DOCUMENTATION_EDGE_DELIVERY_ENGINE: "/getting-started/edge-delivery-engine",
    DOCUMENTATION_CRAWLER_OPTIMIZATION: "/getting-started/crawler-optimization",
    DOCUMENTATION_EDGE_DELIVERY_101: "/getting-started/edge-delivery-101",
    DOCUMENTATION_CLEAR_CACHE: "/clear-cache/how-to-clear-your-cache",
    DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE: "/clear-cache/when-and-why-to-clear-cache",
    DOCUMENTATION_CACHE_HIT_RATIO: "/clear-cache/cache-hit-ratio",
    DOCUMENTATION_STATIC_VS_DYNAMIC_CONTENT: "/clear-cache/static-vs-dynamic-content",
    DOCUMENTATION_CACHING_101: "/clear-cache/caching-101",
    DOCUMENTATION_CACHE_SETTINGS: "/clear-cache/custom-cache-settings",
    DOCUMENTATION_URL_PATHS: "/clear-cache/understanding-url-paths",
    DOCUMENTATION_TURN_OFF_ON: "/debugging/turn-nostra-on-off",
    DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA: "/debugging/test-your-site",
    DOCUMENTATION_MANAGE_EXPERIMENTS: "/ab-testing/manage-experiments",
    DOCUMENTATION_AB_TESTING: "/ab-testing/ab-testing-overview",
    DOCUMENTATION_MANAGE_USERS: "/organization/manage-users",
    DOCUMENTATION_MANAGE_ORGANIZATION: "/organization/manage-organization",
    DOCUMENTATION_MANAGE_ALL_ORGANIZATIONS: "/organization/manage-all-organizations",
    DOCUMENTATION_API: "/api/api-overview",
    DOCUMENTATION_API_ENTIRE_SITE: "/api/clear-entire-site",
    DOCUMENTATION_API_SINGLE_PAGE: "/api/clear-single-page",
    DOCUMENTATION_API_GENERATE_TOKEN: "/api/generate-your-token",
    DOCUMENTATION_INSTALLED_APPS: "/shopify-apps/installed-apps",
    DOCUMENTATION_SINGLE_APP: "/shopify-apps/single-app",
    DOCUMENTATION_APPS_BY_STORE: "/shopify-apps/apps-by-store",
    GET_HELP_NOW: "/troubleshoot",
    DOCUMENTATION_METRICS_ROI: "/metrics/roi",
    DOCUMENTATION_METRICS_TRADITIONAL: "/metrics/traditional",
    DOCUMENTATION_METRICS_KEY_REVENUE: "/metrics/revenue",
    DOCUMENTATION_METRICS_CUSTOMER_JOURNEY: "/metrics/customer-journey",
    DOCUMENTATION_METRICS_WEB_VITALS: "/metrics/web-vitals",
    DOCUMENTATION_METRICS_SEARCH_ENGINE_RANKING: "/metrics/search-engine-page-ranking",
    DOCUMENTATION_METRICS_CRAWLER_OPTIMIZATION: "/metrics/crawler-optimization",
    DOCUMENTATION_METRICS_ADVERTISING: "/metrics/advertising",
    DOCUMENTATION_THIRD_PARTY_DEFAULTS: "/third-party-tools/testing-defaults",
    DOCUMENTATION_THIRD_PARTY_CRUX: "/third-party-tools/crux-report",
    DOCUMENTATION_THIRD_PARTY_LIGHTHOUSE: "/third-party-tools/lighthouse",
    DOCUMENTATION_THIRD_PARTY_PAGESPEED_INSIGHTS: "/third-party-tools/pagespeed-insights",
    DOCUMENTATION_SHOPIFY_PII: "/shopify/what-nostra-reads-from-your-shopify-store",
    DOCUMENTATION_SHOPIFY: "/shopify/shopify-analytics",
    DOCUMENTATION_SHOPIFY_LOADING_SPEED: "/shopify/how-shopify-defines-loading-speed",
    DOCUMENTATION_REPORTS: "/reports/reports-overview",
    DOCUMENTATION_REPORTS_SPEED: "/reports/speed-reports",
    DOCUMENTATION_REPORTS_CRUX: "/reports/crux-reports",
    DOCUMENTATION_REPORTS_PERCENTILES: "/reports/percentiles",
    DOCUMENTATION_REPORTS_STATISTICAL_SIGNIFICANCE: "/reports/statistical-significance",
    STATUS_PAGE: "/status",
    MONITORING_PAGE: "/monitoring",
};

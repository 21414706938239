import { TableContainer, Table, Tbody, Tr, Th, Td, TableCaption, Button, Stack, VStack } from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";
import { useNavigate, useSearchParams, generatePath, useLocation } from "react-router-dom";
import { ROUTES } from "../Routes";
import { Header, SubHeader } from "../components";

export const ConfirmationPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const customerUrl = location.pathname.split("/")[2];
    let [query] = useSearchParams();

    const params = {
        zoneId: query.get("zoneId"),
        name: query.get("name"),
        value: query.get("value"),
    };

    return (
        <PageContainer>
            <VStack alignItems="baseline">
                <Header>New Customer</Header>
                <SubHeader>Cloudflare</SubHeader>
            </VStack>
            <TableContainer>
                <Table variant="unstyled" size="sm" width="40%">
                    <TableCaption placement="bottom">
                        Copy the following DNS TXT record and add it to your domain's DNS records.
                    </TableCaption>
                    <Tbody>
                        {params.name ? (
                            <>
                                <Tr>
                                    <Th>Text Record Name:</Th>
                                    <Td>{params.name}</Td>
                                </Tr>
                                <Tr>
                                    <Th>Text Record Value:</Th>
                                    <Td>{params.value}</Td>
                                </Tr>
                            </>
                        ) : null}

                        <Tr>
                            <Th>Zone Id:</Th>
                            <Td>{params.zoneId}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

            <SubHeader pb="spacer-4">Next Steps</SubHeader>
            <Stack spacing={"spacer-4"} direction="row">
                <Button
                    variant={"outline"}
                    colorScheme={"button-primary"}
                    onClick={() => navigate(generatePath(ROUTES.STORE_EDIT, { customerUrl }))}
                >
                    Edit Store
                </Button>
                <Button colorScheme={"button-primary"} onClick={() => navigate(ROUTES.CREATE_CUSTOMER)}>
                    Create Another Customer
                </Button>
            </Stack>
        </PageContainer>
    );
};

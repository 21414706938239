import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useDeleteVariantMutation = () => useMutation({ mutationFn: deleteVariant });

const deleteVariant = async ({ variantId, customerUrl }: { variantId: string; customerUrl: string }) => {
    const DELETE_VARIANT_URL = `${SVC_URLS["experiment"]}/variations/${variantId}`;
    const options = await fetchOptions({
        method: "DELETE",
        headers: {
            Store: customerUrl,
        },
    });

    const response = await fetch(DELETE_VARIANT_URL, options).catch(handleErrors);

    return fetchResponse(response);
};

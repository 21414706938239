import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Code, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const ClearCacheResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"How To Clear Your Cache"}
            />
            <Header pb={"spacer-8"}>How To Clear Your Cache</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Clear Cache" icon in the <strong>Stores</strong> section will take you to the{" "}
                <strong>Manage Cache</strong> page, where you can choose between clearing your cache by a specific page
                or across your entire site as well as see your cache-clearing history.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/01eb8d89-6985-4a07-a207-c765dbb66b72/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1294,427&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=30,239"
                alt="Clear cache icon"
                loading="eager"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                When To Clear Your Cache
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s {MAIN_PRODUCT} will automatically clear your cache for you after major changes via a
                webhook for Shopify stores or polling for Salesforce Commerce Cloud stores. However, there are still
                moments when you might want to manually clear your cache:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>After making minor yet crucial changes</ListItem>
                <ListItem pb={"spacer-4"}>When your website appears unusual</ListItem>
                <ListItem pb={"spacer-6"}>When you make post plugin/theme adjustments</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To learn more about when manually clearing your cache might be helpful, visit the&nbsp;
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}>
                    When And Why To Clear Your Cache user guide
                </Link>
                .
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Why Clear By A Single Page?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                It is beneficial to keep your website's content in the cache for as long as possible in order to
                optimize your website's speed. However, if pages are kept in the cache indefinitely, customers may see
                outdated content on frequently updated pages. Therefore, it is important to balance cache longevity with
                the need for up-to-date content.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    You may benefit from clearing your cache by page if you regularly update the following elements on
                    specific pages, especially your homepage:
                </strong>
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Text content</ListItem>
                <ListItem pb={"spacer-4"}>Banners</ListItem>
                <ListItem pb={"spacer-4"}>Alerts</ListItem>
                <ListItem pb={"spacer-6"}>Sales</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For instance, consider a scenario where you just made minor changes to a blog page that you want
                reflected immediately. You did not make changes to the rest of your site, so there is no need to update
                your entire store's content in your cache. Using the single-page option will enable you to refresh the
                content on that blog page without duplicating the effort across your entire site.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our site speed experts recommend this single-page approach whenever possible to minimize the time your
                website pages are not stored in the cache for optimal delivery. Of course, there are still instances
                when you will have changed content across many pages when it will make more sense to clear your entire
                site rather than go page by page.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have any questions or would like additional guidance on when to clear your cache by a single page
                versus your entire site,{" "}
                <Link textDecoration="underline" to={ROUTES.SUPPORT} target="new">
                    contact support
                </Link>
                .
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                How To Clear A Single Page Vs. Your Entire Site
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Single Page</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Selecting the "Single Page" option will allow you to clear the cache of a specific page by its full URL
                or{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_URL_PATHS}>
                    URL path
                </Link>
                .
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/7450f07e-7c61-4295-a455-05c741d4ab4d/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1447,651&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=38,260"
                alt="Clear single page"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our intuitive design will manually edit any URL you include to keep only the desired path. If you wanted
                to clear your "example hat" product page and entered this URL:&nbsp;
                <Code>https://example.com/products/example-hat</Code>, the portal interface would intelligently remove
                everything but the intended path: <Code>/products/example-hat</Code>.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    <strong>This feature requires exact matches,</strong> so be sure to add the specific path you want
                    to clear. For example, inputting <Code>/products/</Code> alone will not work more broadly and
                    will&nbsp;
                    <strong>not</strong> capture&nbsp;
                    <Code>/products/example-shirt</Code>. If you need the broader approach, set a{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CACHE_SETTINGS}>
                        custom cache duration
                    </Link>
                    —which does support prefix matches—instead.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Entire Site</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To clear the cache across your store, simply select the "Entire Site" option followed by your desired
                clearing time, explained in more detail below.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/5d13ef92-a14a-4d4a-88b6-c0c4546a5215/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1448,642&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=34,295"
                alt="Clear entire site"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Manage Timing</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Now" option will immediately place your store in the cache-clearing queue. The request status will
                be pending until the operation is complete, and then your request will be moved into your store's
                "History."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Schedule" option will allow you to plan ahead and enter your store in the cache-clearing queue when
                needed. Simply enter the date and time when you want to clear your cache—according to your current
                timezone—and hit "Submit." Your cache clear will then be scheduled.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/8dbc3af1-98d6-4554-b57b-f0c3f836eb6e/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1451,654&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=384,342"
                alt="Schedule button with open calendar"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Schedule Recurring Series
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Clearing a specific page's cache on a regular basis is especially helpful for stores that make
                predictable changes to certain products, pages, etc. but not to others.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s recurring scheduler allows that level of precision and works in the same way a single
                clear cache schedule does when applied to a specific page path.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/5f537345-0b36-4c42-b8d5-e16c06c93545/screenshot.jpeg?tl_px=0,0&br_px=1364,567&force_format=png&width=1120.0"
                alt="Recurring Clear Cache Form"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Enter the page path you would like to clear. Your first recurring job will enter the queue on the
                indicated start date and time and will repeat either until your specified end date or until you cancel
                the recurring series.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Next, select your desired frequency: daily, weekly, monthly, or annually. The frequency options will
                update to match your chosen start date as an added verification method.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/9104b3cb-c749-4dd9-a48f-ebfaeeeeacc6/screenshot.jpeg?tl_px=0,0&br_px=1113,555&force_format=png&width=1120.0"
                alt="Frequency options"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Select "Cancel" to clear the form as needed. If you are satisfied with your selections, press "Submit,"
                and you will see your first recurring cache clearing appear in the pending queue.{" "}
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Pending Clear Cache Jobs
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Below the <strong>Clear Cache</strong> and <strong>Recurring Clear Cache</strong> forms, you will see a
                list of your pending requests. Items that have a trash can icon are single jobs, while items with a
                repeating icon are recurring series.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-10/ef715d3b-4d17-4b5b-9264-6b5b495c3d62/user_cropped_screenshot.jpeg?tl_px=16,0&br_px=1392,301&force_format=png&width=1120.0"
                alt="Pending clear cache jobs"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Recurring Series</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To see information about your recurring series, select the repeating icon. You will see a modal appear
                showing the frequency of your series, the start date, and the end date if one was included in your
                original submission.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Selecting "Delete" in this modal will delete the current item as well as the recurring series. Jobs that
                have already occurred will still show up in your history.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/58555648-9dc5-4fb6-a87f-0865ec2d554f/screenshot.jpeg?tl_px=6,0&br_px=865,406&force_format=png&width=860"
                alt="Series deletion modal"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Single Job</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Single clear cache items only have the ability to be deleted. Selecting the trash can icon will open a
                modal with the option to delete the current job.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/626812db-bb54-415c-bfba-1e59dce57f11/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=728,262&force_format=png&width=860"
                alt="Single job deletion in series"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Configuration
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You must have {BRAND_NAME} enabled to use these cache-clearing features. If {BRAND_NAME} is disabled,
                you cannot clear your cache. You will receive an alert to remind you that our {MAIN_PRODUCT} is set to
                "off" for your store.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-11/ffffe80f-e95e-4583-b9c0-8f344e5c307e/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1968,1281&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=44,281"
                alt="Edge Delivery Engine off alert"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                History
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "History" tab will display up to 100 of your previous cache clearings. You can see who made the
                request and the date and time—again in your timezone—that the request was made. You can also see whether
                your entire site or specific pages were cleared under the "PAGE" column.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Items that include a repeating icon under the "REQUESTED BY" column are (or were) part of a recurring
                series. This serves as a quick reference point so you can better track cache clearings across team
                members and departments.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/d60897c9-2d2c-46d0-a72e-bdb68bb5adbc/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1455,564&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=151,311"
                alt="Clear cache history"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The default view will show cache clearings initiated by either a user with access to the {BRAND_NAME}{" "}
                portal or your store's API call.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you would like to see when {BRAND_NAME}'s system is automatically clearing your cache, select the
                dropdown and choose either "System Automation" or "All." The table will update accordingly.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-09/ada79d73-00a9-4e6f-8540-628235dc2ba4/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1461,561&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=150,128"
                alt="Automation history"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Remember, {BRAND_NAME}'s {MAIN_PRODUCT}{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}>
                    keeps your website's cache up-to-date automatically
                </Link>{" "}
                when:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Product(s) is updated</ListItem>
                <ListItem pb={"spacer-4"}>Inventory levels change</ListItem>
                <ListItem pb={"spacer-6"}>Collection(s) is updated</ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Cache Settings
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Cache Settings" tab is where you can configure your cache duration to optimize your site even
                further.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Cache duration refers to the period of time during which a webpage or a web application's content is
                stored in cache memory. Learn how and why you might want to adjust your cache duration by visiting
                the&nbsp;
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CACHE_SETTINGS}>
                    Custom Cache Settings documentation
                </Link>
                .
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-11/14d1d18e-4cbd-4662-bd13-2d35d3f5aff3/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2041,870&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=966,66"
                alt="Cache settings page"
            />
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};

import { SelectOption } from "../../types";

/**
 * IMPORTANT!
 *
 * String.raw must be used to ensure the STRING representation of the regular expression
 * does not strip out the string escape sequences. This is because the string is passed to the
 * RegExp constructor, which expects a string representation of the regular expression, and the regular
 * expression must not lose the escape sequences.
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/raw
 */
export const pagePatterns: SelectOption[] = [
    { value: String.raw`^\/$`, label: "Homepage" },
    {
        value: String.raw`^\/([a-z][a-z]\/?)?(-[a-z]{2}\/?)?($|products|collections|search|pages|blogs|variants|recommendations)(\/[^.]*?)?$`,
        label: "All Pages",
    },
    {
        value: String.raw`^\/collections\/((?!products)[\s\S])+$`,
        label: "All collection pages",
    },
    {
        value: String.raw`^\/([a-z][a-z]\/?)?(-[a-z]{2}\/?)?(products|collections\/[\S]*\/products\/).*$`,
        label: "All product pages",
    },
    {
        value: String.raw`^\/([a-z][a-z]\/?)?(-[a-z]{2}\/?)?collections\/X(\/$|$)`,
        label: "Specific collection page",
    },
    {
        value: String.raw`^\/([a-z][a-z]\/?)?(-[a-z]{2}\/?)?(products|collections\/[\S]*\/products)\/X$`,
        label: "Specific product page",
    },
];

import { useSearchParams } from "react-router-dom";
import { Header } from "../../../../components";
import { useRevenueReport } from "../../../../hooks";
import { IntervalForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

const CVRMetrics = ({ percentile }: { percentile: number }) => {
    const [searchParams] = useSearchParams();
    const report = "normalized_field_split";
    const metric = "cvr";
    const prefix = `${metric}_p${(percentile * 100).toFixed()}_${report}`;

    const show_text =
        searchParams.get(`${report}_show_text`) === null || searchParams.get(`${report}_show_text`) === "true"
            ? true
            : false;

    const show_labels = searchParams.get(`${report}_show_labels`) === "true" ? true : false;

    const metricParams = {
        metric,
        percentile,
        interval_unit: searchParams.get(`${prefix}_interval_unit`) ?? "days",
        interval_count: searchParams.get(`${prefix}_interval_count`) ?? 365,
        show_text,
        show_labels,
    };

    const { data, isLoading, isError } = useRevenueReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <IntervalForm prefix={prefix} params={metricParams} />
        </RenderMetric>
    );
};

export const CVRByQuantile = () => {
    const percentiles = [1, 0.9, 0.95, 0.85, 0.8, 0.75];
    return (
        <>
            {percentiles.map((percentile) => {
                return (
                    <div key={percentile} style={{ height: "100%", width: "100%" }}>
                        <Header style={{ marginTop: "20px" }} as="h4" textStyle="text-header-L">
                            {`CVR by Quantile: ${percentile}`}
                        </Header>
                        <CVRMetrics percentile={percentile} />
                    </div>
                );
            })}
        </>
    );
};

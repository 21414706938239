import { useMutation } from "@tanstack/react-query";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { UpdateVariantRequest } from "../../types";
import { SVC_URLS } from "../../config/SVC_URLS";

export const useUpdateVariantMutation = () => useMutation({ mutationFn: updateVariant });

const updateVariant = async (request: UpdateVariantRequest) => {
    const { id: variantId } = request.variation;
    const UPDATE_VARIANT = `${SVC_URLS["experiment"]}/variations/${variantId}`;
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(request),
        headers: {
            Store: request.customerUrl,
        },
    });
    const response = await fetch(UPDATE_VARIANT, options).catch(handleErrors);

    return fetchResponse(response);
};

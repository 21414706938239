import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useDeleteExperimentMutation = () => useMutation({ mutationFn: deleteExperiment });

const deleteExperiment = async ({ experimentId, customerUrl }: { experimentId: string; customerUrl: string }) => {
    const DELETE_EXPERIMENT_URL = `${SVC_URLS["experiment"]}/experiment/${experimentId}`;
    const options = await fetchOptions({
        method: "DELETE",
        headers: {
            Store: customerUrl,
        },
    });

    const response = await fetch(DELETE_EXPERIMENT_URL, options).catch(handleErrors);

    return fetchResponse(response);
};

import { PageContainer } from "../PageContainer";
import {
    Text,
    useMediaQuery,
    VStack,
    InputGroup,
    Select,
    Input,
    Button,
    FormControl,
    Flex,
    TableContainer,
    Stack,
    HStack,
    Card,
    CardBody,
    CardHeader,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState, useCallback } from "react";
import { VirtualizedDataTable, FieldSet } from "../../components";
import { GlobalHistoryLogFormData, GlobalHistoryLogRow, LogType } from "../../types";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDateTimeDisplay, truncate } from "../../lib";
import { useListStoreGlobalHistory, generateListLogHistoryQueryKey } from "../../hooks";
import { LoadingPage } from "../LoadingPage";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { MessageDisplay } from "../../components/MessageDisplay";
import { useLocation } from "react-router-dom";
import { useQueryClient, useIsFetching } from "@tanstack/react-query";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Store } from "../../types";

type Props = {
    store: Store;
};

export type GlobalHistoryFilters = {
    dateStart: string;
    dateEnd: string;
    types?: string[];
    users?: string[];
    page?: number;
};

export const ListStoreGlobalHistoryTab = ({ store }: Props) => {
    const location = useLocation();
    const defaultStartDate = dayjs().subtract(1, "week").startOf("day").toISOString();
    const defaultEndDate = dayjs().endOf("day").toISOString();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const initialPage = parseInt(params.get("page") ?? "0", 10);
    const [page, setPage] = useState(initialPage);
    const queryClient = useQueryClient();
    const isFetching = useIsFetching();

    const defaultFilters = {
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
        types: [],
        users: [],
    };

    // TODO: It would be great to move all of these filters to url params
    // maybe when we do the redesign
    const [filters, setFilters] = useState<GlobalHistoryFilters>(defaultFilters);

    const [rows, setRows] = useState<GlobalHistoryLogRow[]>([]);
    const [isMobile] = useMediaQuery("(max-width: 744px)");
    const selectedType: LogType | undefined = undefined;
    const { handleSubmit, register, reset } = useForm<GlobalHistoryLogFormData>({
        defaultValues: {
            dateStart: dayjs(defaultStartDate).format("YYYY-MM-DD"),
            dateEnd: dayjs(defaultEndDate).format("YYYY-MM-DD"),
            selectedType,
            user: "",
        },
    });

    const {
        data: listLogHistory,
        isLoading,
        refetch,
    } = useListStoreGlobalHistory(store.customerUrl, filters, page.toString());

    useEffect(
        function setGlobalHistoryLogRows() {
            if (listLogHistory === undefined) return;
            setRows(listLogHistory?.rows ?? []);
        },
        [listLogHistory],
    );

    const columns = useMemo(buildColumns, []);

    // force reload on back/forward navigation
    useEffect(() => {
        const handlePopstate = () => {
            window.location.reload();
        };
        window.addEventListener("popstate", handlePopstate);
        return () => window.removeEventListener("popstate", handlePopstate);
    }, []);

    const handleSubmitFilters = (formData: GlobalHistoryLogFormData) => {
        const { dateStart, dateEnd, selectedType, user } = formData;
        const formattedDateStart = dateStart === "" ? defaultStartDate : dayjs(dateStart).startOf("day").toISOString();
        const formattedDateEnd = dateEnd === "" ? defaultEndDate : dayjs(dateEnd).endOf("day").toISOString();
        setPage(0);
        setUrlParams(0);

        setFilters((previous) => ({
            ...previous,
            dateStart: formattedDateStart,
            dateEnd: formattedDateEnd,
            types: selectedType ? [selectedType] : [],
            users: user ? [user] : [],
        }));
    };

    const handleClearInputFields = () => {
        reset();
        setFilters(() => defaultFilters);
        setRows(listLogHistory?.rows ?? []);
        setPage(0);
        setUrlParams(0);
    };

    const setUrlParams = useCallback(
        (page: number) => {
            params.set("page", page.toString());
            const updatedUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.pushState({}, "", updatedUrl);
            return updatedUrl;
        },
        [params],
    );

    const handleEarlierPage = useCallback(() => {
        setPage((currentPage) => {
            const nextPage = currentPage + 1;
            setUrlParams(nextPage);
            return nextPage;
        });
    }, [setPage, setUrlParams]);

    const handleLaterPage = useCallback(() => {
        setPage((currentPage) => {
            const previousPage = Math.max(currentPage - 1, 0);
            setUrlParams(previousPage);
            return previousPage;
        });
    }, [setPage, setUrlParams]);

    const handleNextPageRefetch = async () => {
        await handleEarlierPage();
        queryClient.invalidateQueries(generateListLogHistoryQueryKey(store.customerUrl, filters));
        await refetch();
    };

    const handlePreviousPageRefetch = async () => {
        await handleLaterPage();
        queryClient.invalidateQueries(generateListLogHistoryQueryKey(store.customerUrl, filters));
        await refetch();
    };

    return (
        <PageContainer maxWidth="1440px">
            <>
                <FormControl>
                    <form onSubmit={handleSubmit(handleSubmitFilters)}>
                        <VStack alignItems={"flex-start"} width="100%">
                            <Text
                                textStyle={{
                                    base: "text-header-S",
                                    md: "text-header-M",
                                    lg: "text-header-L",
                                }}
                            >
                                Global History
                            </Text>
                            <Text fontWeight={"bold"} pt="spacer-2">
                                All times are your local timezone.
                            </Text>
                            <FieldSet legend="Filter Results" style={{ width: isMobile ? "100%" : "50%" }}>
                                <Stack>
                                    <InputGroup flexDirection={{ base: "column", md: "row" }}>
                                        <Text pr="spacer-2" alignSelf={"center"} fontWeight={"bold"}>
                                            Between:
                                        </Text>
                                        <Input
                                            color={"gray.500"}
                                            width={{ base: "100%", md: "75%" }}
                                            type="date"
                                            {...register("dateStart", {})}
                                        />
                                        <Input
                                            color={"gray.500"}
                                            width={{ base: "100%", md: "75%" }}
                                            type="date"
                                            {...register("dateEnd")}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            placeholder="Select Type"
                                            {...register("selectedType")}
                                            color={"gray.500"}
                                        >
                                            <option value="store">Store</option>
                                            <option value="cache">Cache</option>
                                            <option value="worker">Worker</option>
                                            <option value="fields">Field</option>
                                            <option value="experiment">Experiment</option>
                                        </Select>
                                    </InputGroup>

                                    <Input type="text" placeholder="Filter by User" {...register("user")} />

                                    <Flex alignSelf={"flex-end"} pt={"spacer-4"}>
                                        <Button
                                            variant={"outline"}
                                            width={{ base: "100%", md: "45%" }}
                                            mr={"spacer-1"}
                                            colorScheme="button-primary"
                                            onClick={handleClearInputFields}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            width={{ base: "100%", md: "45%" }}
                                            colorScheme="button-primary"
                                            type="submit"
                                        >
                                            Apply
                                        </Button>
                                    </Flex>
                                </Stack>
                            </FieldSet>
                        </VStack>
                    </form>
                </FormControl>
                {isLoading || isFetching ? (
                    <LoadingPage />
                ) : listLogHistory === undefined || listLogHistory?.rows?.length === 0 ? (
                    <Text
                        textStyle={{
                            base: "text-header-S",
                            md: "text-header-M",
                            lg: "text-header-L",
                        }}
                        justifyContent="center"
                        display="flex"
                        mt={{ base: "spacer-4", md: "8rem" }}
                    >
                        {"No records found."}
                    </Text>
                ) : (
                    <>
                        <HStack py={"spacer-2"} justifyContent={{ base: "center", md: "flex-end" }}>
                            <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                                Previous
                            </Button>
                            <Button
                                onClick={handleNextPageRefetch}
                                isDisabled={!listLogHistory?.hasMore}
                                colorScheme="button-primary"
                            >
                                Next
                            </Button>
                        </HStack>

                        {isMobile ? (
                            <div style={{ height: "calc(90vh)", width: "100%", paddingTop: "1rem" }}>
                                <AutoSizer>
                                    {({ height, width }: { height: number; width: number }) => {
                                        const ITEM_SPACING = 8;
                                        const ITEM_SIZE = 230;
                                        return (
                                            <List
                                                height={height}
                                                width={width}
                                                itemCount={rows.length}
                                                itemSize={ITEM_SIZE + ITEM_SPACING}
                                            >
                                                {({ index, style }) => (
                                                    <Card
                                                        width={"100%"}
                                                        size="md"
                                                        key={rows[index].date}
                                                        style={{
                                                            ...style,
                                                            height: ITEM_SIZE,
                                                            backgroundColor:
                                                                index % 2 === 0
                                                                    ? "var(--chakra-colors-gray-100)"
                                                                    : "rgba(0,0,0,0)",
                                                        }}
                                                    >
                                                        <CardHeader pb="0">
                                                            <Text display="inline-block" fontWeight={"bold"}>
                                                                {formatDateTimeDisplay(rows[index].date)}
                                                            </Text>
                                                        </CardHeader>
                                                        <CardBody paddingTop={"spacer-2"}>
                                                            <Text display="inline-block" pb={"spacer-2"}>
                                                                <strong>User:</strong> {rows[index].user}
                                                            </Text>
                                                            <Text display="inline-block" pb={"spacer-2"}>
                                                                <strong>Type: </strong> {rows[index].type}
                                                            </Text>
                                                            <Text display="inline-block" pb={"spacer-2"}>
                                                                <strong>Message: </strong>
                                                                {truncate(
                                                                    JSON.stringify(rows[index].data, null, 2),
                                                                    50,
                                                                )}
                                                            </Text>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </List>
                                        );
                                    }}
                                </AutoSizer>
                            </div>
                        ) : (
                            <TableContainer pt={"spacer-6"}>
                                <VirtualizedDataTable columns={columns} data={rows} tableShift="auto" />
                            </TableContainer>
                        )}

                        <HStack py={"spacer-2"} justifyContent={{ base: "center", md: "flex-end" }}>
                            <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                                Previous
                            </Button>
                            <Button
                                onClick={handleNextPageRefetch}
                                isDisabled={!listLogHistory?.hasMore}
                                colorScheme="button-primary"
                            >
                                Next
                            </Button>
                        </HStack>
                    </>
                )}
            </>
        </PageContainer>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<GlobalHistoryLogRow>();
    const columns = [
        columnHelper.accessor("date", {
            cell: (props) => formatDateTimeDisplay(props.getValue()),
            header: "Date",
        }),
        columnHelper.accessor("user", {
            cell: (props) => props.getValue(),
            header: "User",
        }),
        columnHelper.accessor("type", {
            cell: (props) => props.getValue(),
            header: "Type",
        }),
        columnHelper.accessor("data", {
            cell: (props) => MessageDisplay(JSON.stringify(props.getValue(), null, 2)),
            header: "Message",
        }),
    ];

    return columns;
};

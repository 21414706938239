import { useToast, FormControl, Flex, Button } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Label, InputJson } from "../../components";
import { generateExperimentQueryKey, useUpdateVariantMutation } from "../../hooks";
import { toJSON } from "../../lib";
import { Variation, Experiment } from "../../types";

export const AdvancedEditVariationForm = ({ variation, experiment, customerUrl }: AdvancedEditVariationFormProps) => {
    const defaultValues = {
        variation: JSON.stringify(variation, null, 4),
    };
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm<UpdateVariationFormData>({ defaultValues });

    const updateVariantMutation = useUpdateVariantMutation();
    const showToast = useToast({
        duration: 3000,
        isClosable: true,
    });
    const queryClient = useQueryClient();

    const updateVariation = (formData: UpdateVariationFormData) => {
        const variation = toJSON(formData.variation, {
            onError: () => {
                showToast({
                    title: "Error: Advanced Edit Variation",
                    description: `Unable to parse JSON: ${variation.name}!`,
                    status: "error",
                    duration: null,
                });
            },
        });

        const request = {
            orgId: experiment.orgId,
            experimentId: experiment.id,
            variation,
            customerUrl,
        };

        const onSuccess = () => {
            queryClient.invalidateQueries(generateExperimentQueryKey(experiment.id));

            showToast({
                title: "Success: Advanced Edit Variation",
                description: `"${variation.name}" updated!`,
                status: "success",
            });
        };

        const onError = () => {
            showToast({
                title: "Error: Advanced Edit Variation",
                description: `An error occurred while trying to update ${variation.name}!`,
                status: "error",
                duration: null,
                isClosable: true,
            });
        };

        updateVariantMutation.mutate(request, { onSuccess, onError });
    };

    // When variation is updated from the parent components, reset the form with the updated values
    useEffect(() => reset({ variation: JSON.stringify(variation, null, 4) }), [variation, reset]);

    return (
        <FormControl key={variation.id}>
            <form onSubmit={handleSubmit(updateVariation)}>
                <Label align={"left"} mb="0.5rem" as="b">
                    {variation.name}
                </Label>
                <InputJson<UpdateVariationFormData>
                    {...{
                        name: "variation",
                        formSettings: {
                            register,
                            setValue,
                            errors,
                            watch,
                        },
                    }}
                ></InputJson>

                <Flex pb="spacer-4">
                    <Button isDisabled={experiment.active} colorScheme="button-primary" type="submit" ml="auto">
                        Submit
                    </Button>
                </Flex>
            </form>
        </FormControl>
    );
};

type AdvancedEditVariationFormProps = {
    variation: Variation;
    experiment: Experiment;
    customerUrl: string;
};

type UpdateVariationFormData = {
    variation: string;
};

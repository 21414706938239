import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { Input, Button, Flex, Select } from "@chakra-ui/react";
import { FieldError, FieldSet, Label } from "../../../../components";
import { percentileOptions } from "../../../../lib";

export const ReportParameters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            from_hour: searchParams.get("dateStart") ?? dayjs().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
            upto_hour: searchParams.get("dateEnd") ?? dayjs().endOf("day").format("YYYY-MM-DD"),
            percentile: searchParams.get("percentile") ?? ".75",
        },
    });

    const handleReportSubmit = (data: any) => {
        setSearchParams(data);
    };

    const beginningOfDataCollection = "2024-06-01";

    return (
        <form onSubmit={handleSubmit(handleReportSubmit)}>
            <FieldSet legend="Parameters" style={{ marginTop: 12 }}>
                {/* Date Range */}
                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                    From
                </Label>
                <Input
                    type="date"
                    {...register("from_hour", { required: true })}
                    min={beginningOfDataCollection}
                    maxWidth={150}
                />
                <FieldError>{errors.from_hour?.message}</FieldError>

                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                    To
                </Label>
                <Input type="date" {...register("upto_hour", { required: true })} maxWidth={150} />
                <FieldError>{errors.upto_hour?.message}</FieldError>

                {/* Percentile */}
                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                    Percentile
                </Label>
                <Select
                    {...register("percentile", { required: true })}
                    isRequired={true}
                    maxWidth="fit-content"
                    display={"inline-flex"}
                >
                    {percentileOptions.map((option) => (
                        <option value={option.value} key={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
                <FieldError>{errors.percentile?.message}</FieldError>
            </FieldSet>

            <Flex pb="spacer-6" justifyContent={"flex-end"}>
                <Button colorScheme={"button-primary"} type="submit">
                    Submit
                </Button>
            </Flex>
        </form>
    );
};

import { JSONString, Store, StoreModes, Bulletin } from "./types";

export type SelectOption = {
    value: string;
    label: string;
};

export type PageSetFormData = {
    name: string;
    samplePageUrl: string;
    pageType: string;
    pagePattern: string;
};

export type CreateExperimentFormData = {
    experimentName: string;
    pageSetName: string;
    isDesktopEligible: boolean;
    isMobileEligible: boolean;
} & Omit<PageSetFormData, "name">;

export type UpdateStoreFormData = Partial<
    Omit<
        Store,
        | "id"
        | "orgId"
        | "workerRoutes"
        | "classificationCacheable"
        | "classificationDocument"
        | "classificationProtected"
    >
> & {
    workerRoutes?: JSONString;
    classificationCacheable?: JSONString;
    classificationDocument?: JSONString;
    classificationProtected?: JSONString;
    earlyHints?: JSONString;
    countryDefault?: string | null;
    logLevel?: string;
    orderSyncCursor?: string | null;
    ignoreUtmParameters?: boolean;
};

export type UpdateModeFormData = {
    mode: StoreModes;
    updateModeReason?: string;
    testModePath?: string;
};

export type BulletinFormData = Omit<Bulletin, "id" | "createdAt" | "updatedAt" | "createdBy" | "deleted">;

export type ScheduleClearCacheFormData = {
    scheduleValue: string;
    selectedDate: string;
    path: string;
    scheduleTypeOption: string;
    singleFavorite?: string;
};

export type GlobalHistoryLogFormData = {
    dateStart: string;
    dateEnd: string;
    selectedType: LogType;
    user: string;
};

export enum LogType {
    Store = "store",
    Worker = "worker",
    Cache = "cache",
    Experiment = "experiment",
    Field = "fields",
}

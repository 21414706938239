import {
    useToast,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Stack,
    FormControl,
    Input,
    Button,
    FormLabel,
    Spacer,
    DrawerCloseButton,
    Flex,
    Divider,
} from "@chakra-ui/react";
import { useCreateVariantMutation, useMotionProps } from "../../hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import { CreateVariantRequest } from "../../types";
import { useQueryClient } from "@tanstack/react-query";
import { FieldError, Header } from "../../components";

type Props = {
    orgId: string;
    experimentId: string;
    isOpen: boolean;
    onClose: () => void;
    customerUrl: string;
};

export const NewVariantDrawerForm = ({ orgId, experimentId, isOpen, onClose, customerUrl }: Props) => {
    const createVariantMutation = useCreateVariantMutation(customerUrl);
    const showToast = useToast();
    const queryClient = useQueryClient();
    const motionProps = useMotionProps();

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm<NewVariantFormData>({
        defaultValues: initialFormValues,
    });

    const onSuccess = () => {
        queryClient.invalidateQueries(["experiment", experimentId]);
        showToast({
            title: "Success",
            description: "Variation created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        reset(initialFormValues);
        onClose();
    };

    const onError = (error: any) => {
        showToast({
            title: error.name || "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    };

    const onSubmit: SubmitHandler<NewVariantFormData> = async (formData: NewVariantFormData) => {
        const request: CreateVariantRequest = buildCreateVariantRequest(formData, orgId, experimentId);

        createVariantMutation.mutate(request, {
            onSuccess,
            onError,
        });
    };

    return (
        <Drawer
            isOpen={isOpen}
            onCloseComplete={() => reset()}
            placement="right"
            onClose={onClose}
            size={{ base: "xs", md: "sm" }}
        >
            <DrawerOverlay />
            <DrawerContent motionProps={motionProps}>
                <Flex align="center" ml={3}>
                    <DrawerCloseButton position="static" />
                    <Header textStyle={"text-header-M"} ml={3}>
                        Add Variation
                    </Header>
                    <Spacer />
                    <Button size="sm" form="myForm" type="submit" colorScheme="button-primary" m={3}>
                        Save
                    </Button>
                </Flex>
                <Divider />
                <DrawerBody>
                    <Stack spacing={6}>
                        <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isRequired>
                                <FormLabel>Variation Name</FormLabel>
                                <Input
                                    width="100"
                                    type="text"
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "Variation name is required",
                                        },
                                    })}
                                />
                                <FieldError>{errors.name?.message}</FieldError>
                            </FormControl>
                        </form>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

const buildCreateVariantRequest = (
    formData: NewVariantFormData,
    orgId: string = "",
    experimentId: string = "",
): CreateVariantRequest => {
    return {
        orgId: orgId,
        experimentId: experimentId,
        name: formData.name,
    };
};

const initialFormValues = {
    name: "",
};

type NewVariantFormData = {
    orgId: string;
    experimentId: string;
    name: string;
};

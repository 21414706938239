import { useMutation } from "@tanstack/react-query";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { UpdateVariantNameRequest } from "../../types";
import { SVC_URLS } from "../../config/SVC_URLS";

export const useUpdateVariantNameMutation = () => useMutation({ mutationFn: updateVariantName });

// @ToDo: Does this need to be an endpoint when we now have UpdateVariation endpoint?
const updateVariantName = async (request: UpdateVariantNameRequest) => {
    const { id: variantId } = request;
    const UPDATE_NAME = `${SVC_URLS["experiment"]}/variations/${variantId}/name`;
    const options = await fetchOptions({
        method: "PATCH",
        body: JSON.stringify(request),
        headers: {
            Store: request.customerUrl,
        },
    });
    const response = await fetch(UPDATE_NAME, options).catch(handleErrors);

    return fetchResponse(response);
};
